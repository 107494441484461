import {useRouter} from 'next/router';
import {pathOr} from 'ramda';
import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';

import {ROLES_NAMES} from '@/src/app/constants/roles';
import {selectClinicData} from '@/src/app/store/clinic/clinicDataSlice';
import useUserActions from '@/src/app/store/user/useUserActions';
import {getAuthorizationToken} from '@/src/app/utils/authorizationToken';
import BannerSubsEnded from '@/src/header/components/BannerSubsEnded';
import TrialDaysLeft from '@/src/header/components/TrialDaysLeft';
import useSubscribeBannerStatus from '@/src/header/hooks/useSubscribeBannerStatus';
import getProfilePicture from '@/src/header/utils/getProfilePicture';
import useIsMobile from '@/src/hooks/useIsMobile';
import useRoutePaths from '@/src/hooks/useRoutePaths';
import {getUserInitials} from '@/src/profile/common/containers/MyProfileWrapper';

import HeaderLayout from '../components/HeaderLayout';

export const ROOT_PAGE = '/';

const Header = () => {
  const {user} = useUserActions();
  const picture = pathOr('', ['profile', 'picture'], user);
  const {pathname, asPath, locale} = useRouter();
  const {isMaxWidth1100} = useIsMobile();
  const {HOW_T0, DISCOVER} = useRoutePaths();
  const clinicData = useSelector(selectClinicData);

  const isDiscoverPage = asPath.includes(DISCOVER.getLocalizationLink(locale));

  const isRootPage = useMemo(
    () => '/landing-v2' === asPath || pathname === ROOT_PAGE || isDiscoverPage,
    [pathname, asPath, isDiscoverPage]
  );

  const {isShow, leftDays, onCloseClick, isSubscribeEnded} =
    useSubscribeBannerStatus();

  return (
    <>
      <HeaderLayout
        isRootPage={isRootPage}
        isAuthorized={Boolean(getAuthorizationToken())}
        userInitials={getUserInitials(
          user,
          user.user.type === ROLES_NAMES.CLINIC,
          clinicData
        )}
        picture={getProfilePicture(picture, clinicData, user)}
        user={user}
        isHowToMenu={asPath.includes(HOW_T0.path) && isMaxWidth1100}
      />
      <TrialDaysLeft
        daysLeft={leftDays}
        isShow={isShow}
        onCloseClick={onCloseClick}
      />
      {isSubscribeEnded && <BannerSubsEnded />}
    </>
  );
};

export default Header;
