import cn from 'clsx';
import NavLink from 'next/link';
import {FC} from 'react';

import CloseButton from '@/public/icons/closePopup.svg';
import WarningIcon from '@/public/icons/fullfiledWarningIcon.svg';
import useRoutePaths from '@/src/hooks/useRoutePaths';
import TextLocal from '@/src/textLocal/containers/TextLocal';

import css from '../styles/TrialDaysLeft.module.css';

interface TrialDaysLeftProps {
  daysLeft: number;
  onCloseClick: () => void;
  isShow: boolean;
}

const TrialDaysLeft: FC<TrialDaysLeftProps> = ({
  daysLeft,
  onCloseClick,
  isShow,
}) => {
  const {MY_PROFILE_SUBSCRIPTION} = useRoutePaths();

  if (!isShow) {
    return null;
  }

  return (
    <div className={cn(css.main)}>
      <div className={css.content}>
        <span className={css.warningIcon}>
          <WarningIcon />{' '}
        </span>
        <TextLocal
          id={
            daysLeft <= 1 ? 'sign-up.trial-end-in-one' : 'sign-up.trial-end-in'
          }
          params={{
            days: daysLeft,
          }}
        />
        <NavLink href={MY_PROFILE_SUBSCRIPTION.path}>
          <a>
            <TextLocal id={'sign-up.add-payment'} />{' '}
          </a>
        </NavLink>
        <button className={css.closeButton} onClick={onCloseClick}>
          <CloseButton />
        </button>
      </div>
    </div>
  );
};

export default TrialDaysLeft;
